<template>
  <div class="dashboard">
    <div class="dashboard__sidebar">
      <div class="dashboard__sidebar-title">Rhymakr</div>
      <div class="dashboard__sidebar-actions">
        <el-button
          class="dashboard__sidebar-actions-add"
          type="primary"
          icon="el-icon-plus"
          round
          @click="redirectTo('Editor')">New Project</el-button>
        <el-button
          class="dashboard__sidebar-actions-add"
          type="primary"
          icon="el-icon-plus"
          round @click="redirectTo('AudioManager')" >New Audio</el-button>
      </div>
    </div>
    <div class="dashboard__main">
      <div class="dashboard__main-header">
        <div>LEFT ITEM</div>
        <div>RIGHT ITEM</div>
      </div>
      <div class="dashboard__forms">
        <div v-for="form in forms" :key="form">
          <div class="dashboard__single-form-item">{{ form.name }}</div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>

export default {
  name: "Dashboard",
  data() {
    return {
      forms: [
        {name: 'FORM1'},
        {name: 'FORM2'},
        {name: 'FORM4'},
        {name: 'FORM5'},
        {name: 'FORM6'},
        {name: 'FORM7'},
        {name: 'FORM8'},
        {name: 'FORM9'},
        {name: 'FORM10'},
        {name: 'FORM11'},
        {name: 'FORM12'},
      ],
      results: []
    }
  },
  methods: {
    redirectTo(route) {
      this.$router.push({name : route})
    },
  },

};
</script>

<style lang="scss" scoped>
.dashboard{
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #F6F7F8;

  &__sidebar{
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 8px;
    margin: 30px 10px;
    width: 100%;
    max-width: 200px;
    background-color: #05445E;
    padding: 20px;
    color: whitesmoke;
  }

  &__sidebar-title{
    font-size: 32px;
    margin-bottom: 30px;
    width: 100%;
  }

  &__sidebar-actions{
    display: flex;
    flex-direction: column;

  }

  &__sidebar-actions-add{
    background-color: whitesmoke;
    border: none !important;
    color: #05445E;
    margin: 25px 0;
  }

  &__main{
    display: flex;
    flex-direction: column;
    flex: 4;
    width: 100%;
    padding: 30px 120px;

  }

  &__main-header{
    width: 100%;
    border-radius: 8px;
    padding: 16px 8px;
    background-color: #05445E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: whitesmoke;
  }

  &__forms{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__single-form-item{
    display: flex;
    text-align: center;
    background-color: #D4F1F4;
    border-radius: 8px;
    padding: 15px 25px;
    margin: 20px 12px 20px 12px;
    width: 260px;
    height: 140px;
    color: dimgrey;
  }

}





</style>
